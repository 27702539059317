// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cities-js": () => import("./../../../src/pages/cities.js" /* webpackChunkName: "component---src-pages-cities-js" */),
  "component---src-pages-explore-js": () => import("./../../../src/pages/explore.js" /* webpackChunkName: "component---src-pages-explore-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-about-page-template-js": () => import("./../../../src/templates/about-page-template.js" /* webpackChunkName: "component---src-templates-about-page-template-js" */),
  "component---src-templates-add-your-city-page-template-js": () => import("./../../../src/templates/add-your-city-page-template.js" /* webpackChunkName: "component---src-templates-add-your-city-page-template-js" */),
  "component---src-templates-city-template-js": () => import("./../../../src/templates/city-template.js" /* webpackChunkName: "component---src-templates-city-template-js" */),
  "component---src-templates-contact-page-template-js": () => import("./../../../src/templates/contact-page-template.js" /* webpackChunkName: "component---src-templates-contact-page-template-js" */),
  "component---src-templates-how-to-act-page-template-js": () => import("./../../../src/templates/how-to-act-page-template.js" /* webpackChunkName: "component---src-templates-how-to-act-page-template-js" */),
  "component---src-templates-index-page-template-js": () => import("./../../../src/templates/index-page-template.js" /* webpackChunkName: "component---src-templates-index-page-template-js" */),
  "component---src-templates-methodology-page-template-js": () => import("./../../../src/templates/methodology-page-template.js" /* webpackChunkName: "component---src-templates-methodology-page-template-js" */),
  "component---src-templates-outcomes-page-template-js": () => import("./../../../src/templates/outcomes-page-template.js" /* webpackChunkName: "component---src-templates-outcomes-page-template-js" */),
  "component---src-templates-read-the-report-page-template-js": () => import("./../../../src/templates/read-the-report-page-template.js" /* webpackChunkName: "component---src-templates-read-the-report-page-template-js" */)
}

