// Requiring the CSS from Mapbbox GL here ensures the styles are available immediately in the browser
require("mapbox-gl/dist/mapbox-gl.css");

// Adding a scroll API polyfill for use in browsers like IE/Edge
const smoothscroll = require("smoothscroll-polyfill");
smoothscroll.polyfill();

// The following adds a body class to inform the styles when to use IE specific styles
(function(){
  var ua, re;
  if (navigator.appName === 'Microsoft Internet Explorer') {
    ua = navigator.userAgent;
    re = new RegExp("MSIE ([0-9]{1,}[\\.0-9]{0,})");
    if (re.exec(ua) !== null)
      document.body.classList.add('is-ie');
  }
  else if (navigator.appName === 'Netscape') {
    ua = navigator.userAgent;
    re = new RegExp("Trident/.*rv:([0-9]{1,}[\\.0-9]{0,})");
    if (re.exec(ua) !== null)
      document.body.classList.add('is-ie');
  }
})();
